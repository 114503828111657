import { render, staticRenderFns } from "./Home.vue?vue&type=template&id=f1d32320&scoped=true"
import script from "./Home.vue?vue&type=script&lang=js"
export * from "./Home.vue?vue&type=script&lang=js"
import style0 from "./Home.vue?vue&type=style&index=0&id=f1d32320&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f1d32320",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Story1: require('/var/www/dockerize-nuxt/nuxt-app/components/story/Story1.vue').default,Story12: require('/var/www/dockerize-nuxt/nuxt-app/components/story/Story12.vue').default,Story7: require('/var/www/dockerize-nuxt/nuxt-app/components/story/Story7.vue').default})
