
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    position: {
      type: String,
      required: false
    },
  },
}
