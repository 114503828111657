
export default {
  props: {
    rank: {
      type: Number,
      required: false,
      defaults: 1
    },
    showTitle: {
      type: Boolean,
      required: false
    }
  },
}
