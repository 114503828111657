
export default {
  props: {
    story: {
      type: Object,
      required: false
    },
    index: {
      type: Number,
      required: false
    },
    tag: {
      type: String,
      required: false
    },
  },
}
