
import RequestUtil from "@/utils/request-util";
import {getHistoryStory} from "@/utils/auth";
import ChartAuthor from "@/components/commons/ChartAuthor.vue";

export default {
  components: {
    ChartAuthor,
    LabelPrimary: () => import('@/components/commons/LabelPrimary'),
    Widgets: () => import('@/components/commons/Widgets'),
    Story1: () => import('@/components/story/Story1'),
    Story3: () => import('@/components/story/Story3'),
    Story4: () => import('@/components/story/Story4'),
    Story5: () => import('@/components/story/Story5'),
    Story7: () => import('@/components/story/Story7.vue'),
    Story12: () => import('@/components/story/Story12.vue'),
  },
  data() {
    return {
      storyPopular: this.defaultStory(8),
      storyNew: this.defaultStory(10),
      storyNewChapter: this.defaultStory(10),
      storySuggest: this.defaultStory(5),
      storyFull: this.defaultStory(12),
      storyHistory: [],
      eventResults: {},
      translatePx: 0,
      timeLoading: 0,
      hasClick: false,
      isMobile: false,
      showModal: true,
      translateX: 'translateX(0px)'
    }
  },
  mounted() {
    this.$store.dispatch('showHeader')
    this.fetchStoryPopular();
    this.getStoryFull();
    this.fetchStoryNew();
    this.fetchStorySuggest();
    // this.getEventWritingResult();
    // this.fetchStoryHistory();
    this.pushLogs();
    this.startInterval();
    window.addEventListener('resize', this.checkScreenSize);

  },
  methods: {


    showNotification() {
      this.$notify({
        title: 'Notification Title',
        text: 'Notification Content',
        type: 'success'
      })
    },
    // async getEventWritingResult() {
    //   let res = await RequestUtil.get('api/v1/event/event_story_result?size=10')
    //   if (res.status === 200) {
    //     this.eventResults = res.data
    //   }
    // },
    async fetchStoryHome() {
      const response = await RequestUtil.get('/api/v1/story?collection=HOME');
      this.storyPopular = response.data.popular
      this.storySuggest = response.data.suggest
      this.storyNew = response.data.storyNew
      this.storyNewChapter = response.data.chapter

    },
    async fetchStoryPopular() {
      const popularResponse = await RequestUtil.get('/api/v1/story?collection=POPULAR&size=9');
      this.storyPopular = popularResponse.data
    },
    async getStoryFull() {
      const popularResponse = await RequestUtil.get('/api/v1/story?collection=FULL&size=12');
      this.storyFull = popularResponse.data
    },
    async pushLogs() {
      await RequestUtil.get('/api/v1/story/logs?type=HOME');
    },
    async fetchStorySuggest() {
      const popularResponse = await RequestUtil.get('/api/v1/story?collection=SUGGEST&size=5');
      this.storySuggest = popularResponse.data

    },
    async fetchStoryNew() {
      const storyNewResponse = await RequestUtil.get('/api/v1/story?collection=NEW&size=10');
      this.storyNew = storyNewResponse.data
    },
    async fetchStoryHistory() {
      let history = new Map(JSON.parse(getHistoryStory()));
      let storyIds = [...history.keys()];
      storyIds = storyIds.reverse();
      let storyIdsStr = storyIds.toString()
      const storyHistory = await RequestUtil.get('/api/v1/story/_bulk?ids=' + storyIdsStr);
      let storyHistoryData = storyHistory.data;
      let mapStoryHistory = new Map();
      for (const k in storyHistoryData) {
        mapStoryHistory.set(storyHistoryData[k].id, storyHistoryData[k])
      }
      for (const idKey in storyIds) {
        let story = mapStoryHistory.get(storyIds[idKey]);
        if (story === undefined) {
          continue;
        }
        story['chapterReading'] = history.get(storyIds[idKey])
        if (this.storyHistory.length < 4) {
          this.storyHistory.push(story)
        }
      }
    },
    translateList(plus, isClick) {
      if (isClick) {
        this.hasClick = true;
      }
      const translatePx = plus ? this.translatePx + 255 : this.translatePx - 255
      const translatePxMax = window.innerWidth <= 768 ? -2255 : -1100;
      if (translatePx > 0 || translatePx < translatePxMax) {
        return;
      }
      this.translatePx = translatePx;
      this.translateX = 'translateX(' + translatePx + 'px)'
    },
    checkScreenSize() {
      this.isMobile = window.innerWidth <= 768;
    },
    startInterval() {
      this.interval = setInterval(() => {
        if(!this.hasClick) {
          this.translateList(false, false);
        }
      }, 2000); // Lặp lại mỗi 2 giây
    },
  },
  beforeDestroy() {
    clearInterval(this.interval);
    window.removeEventListener('resize', this.checkScreenSize);
  },
}
