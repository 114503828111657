
import Widgets from "@/components/commons/Widgets.vue";
import LabelPrimary from "@/components/commons/LabelPrimary.vue";
import RequestUtil from "@/utils/request-util";
import Loading from "@/components/commons/Loading.vue";
import AuthorTopItem from "../author/AuthorTopItem.vue";
export default {
  components: {
    AuthorTopItem,
    Loading,
    Widgets,
    LabelPrimary,
  },
  data() {
    return {
      authorTops: [],
      authorFavourites: [],
      authorHard: [],
      isLoading: false,
      filter: {
        year: null,
        month: null,
        size: 5,
      },
    }
  },
  mounted() {
    this.getAuthorExcellent();
  },
  created() {
    const now = new Date();
    now.setDate(now.getDate() - 1);
    this.filter.year = now.getFullYear();
    this.filter.month = now.getMonth() + 1;
  },
  methods: {
    async getAuthorExcellent() {
      if (this.authorTops.length === 0) {
        this.isLoading = true;
        const res = await RequestUtil.get('/api/v1/author/chart?sort=point,desc', this.filter);
        this.authorTops = res.data
        this.isLoading = false;
      }

    },
    async getAuthorFavourite() {
      if (this.authorFavourites.length === 0) {
        this.isLoading = true;
        const res = await RequestUtil.get('/api/v1/author/chart?sort=pointFavourite,desc', this.filter);
        this.authorFavourites = res.data
        this.isLoading = false;
      }
    },
    async getAuthorHard() {
      if (this.authorHard.length === 0) {
        this.isLoading = true;
        const res = await RequestUtil.get('/api/v1/author/chart?sort=pointChapter,desc', this.filter);
        this.authorHard = res.data
        this.isLoading = false;
      }
    },
  },
}
