
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    border: {
      type: String,
      required: false
    },
    fontSize: {
      type: String,
      required: false,
    },
  },
}
