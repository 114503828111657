import { render, staticRenderFns } from "./AuthorTopItem.vue?vue&type=template&id=31fda2a7"
import script from "./AuthorTopItem.vue?vue&type=script&lang=js"
export * from "./AuthorTopItem.vue?vue&type=script&lang=js"
import style0 from "./AuthorTopItem.vue?vue&type=style&index=0&id=31fda2a7&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AuthorName: require('/var/www/dockerize-nuxt/nuxt-app/components/author/AuthorName.vue').default})
