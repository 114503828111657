
export default {
  props: {
    author: {
      type: Object,
      required: false
    },
    index: {
      type: Number,
      required: false
    },
    unit: {
      type: String,
      required: false
    },
  },
}
