
import AuthorRank from "./AuthorRank.vue";

export default {
  components: {AuthorRank},
  props: {
    author: {
      type: Object,
      required: true
    },
    showVerify: {
      type: Boolean,
      required: true,
      defaults: true
    }
  },
}
